@let options = items();

<mat-form-field class="full-width"
                [style.margin-bottom]="!showHint ? '-1.25em' : 'auto'">
  <mat-label>{{label}}</mat-label>
  <mat-select [disabled]="disabled"
              (selectionChange)="itemSelected($event)"
              [compareWith]="compareWith"
              [required]="required"
              [value]="selectedItem">
    @for(option of options; track option) {
    <mat-option [value]="option"
                [disabled]="checkItemDisabled(option)">{{displayWith(option)}}</mat-option>
    }
  </mat-select>
  @if(clearable && selectedItem) {
  <button matButton
          matSuffix
          mat-icon-button
          (click)="$event.stopPropagation();selectedItem = undefined;selected.emit(undefined)"
          matTooltip="Clear">
    <mat-icon>close</mat-icon>
  </button>
  }
  @if(loadingState() === LoadingState.LOADING) {
  <mat-spinner diameter="15"
               color="primary"
               matSuffix></mat-spinner>
  }
</mat-form-field>

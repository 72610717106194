import { DataUtil } from './data.util';

export class ImageUtil {

  public static async getImageFileDimensions(file: File): Promise<Pick<ImageBitmap, 'height' | 'width'>> {
    const bmp = await createImageBitmap(file);
    const dimensions = { width: bmp.width, height: bmp.height };
    bmp.close();

    return dimensions;
  }

  public static async getImageFileRatio(file: File): Promise<number> {
    const dimensions = await this.getImageFileDimensions(file);

    return DataUtil.roundToDecimal(dimensions.width / dimensions.height, 2);
  }

  public static verifyAspectRatio(requiredRatio: number, width: number, height: number, allowedOffset = 0): string | undefined {
    const givenRatio = width / height;

    const difference = Math.abs(requiredRatio - givenRatio) / requiredRatio;
    const offset = difference > 1 ? 1 : difference; // if shift is more than 100%, still treat it as 100%

    if (!(offset <= allowedOffset / 100)) {
      const locale = navigator.language || navigator.languages[0];
      throw new Error(`Image ratio (${givenRatio.toLocaleString(locale, { maximumFractionDigits: 2 })}) is too off. The required ratio is ${requiredRatio.toLocaleString(locale, { maximumFractionDigits: 2 })} with a possible deviation of ${allowedOffset}%`);
    }

    return undefined;
  }
}

import { Component, ElementRef, EventEmitter, inject, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ThemePalette } from '@angular/material/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-button',
  standalone: true,
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    NgIf,
    RouterLink,
  ],
})
export class ButtonComponent implements OnDestroy {
  private dialog = inject(MatDialog);

  @Input() label: string | undefined;
  @Input() destructive = false;
  @Input() disabled = false;
  @Input() link = false;

  @Input() color: ThemePalette = 'primary';
  @Input() secondary = false;

  @Input() icon: string | undefined;

  @Input() routerLink: string | string[] | undefined;
  @Input() href: string | undefined;
  @Input() target = '_blank';
  @Input() tooltip?: string;
  @Input() confirmation?: boolean;
  @Input() confirmationMessage = 'Confirm your action?';
  @Input() confirmationDescription?: string;

  @Input() width = 'auto';

  @Output() clicked = new EventEmitter<MouseEvent>();

  @ViewChild('confirmationDialogTpl') confirmationDialogTpl?: TemplateRef<ElementRef>;
  private readonly onDestroy$ = new Subject<void>();

  protected click($event: MouseEvent): void {
    if (this.confirmation) {
      this.openConfirmationDialog($event);
      return;
    } else {
      this.clicked.emit($event);
    }
  }

  private openConfirmationDialog($event: MouseEvent): void {
    if (this.confirmationDialogTpl) {
      this.dialog.open(this.confirmationDialogTpl, { width: '400px', }).afterClosed().pipe(
        take(1),
        takeUntil(this.onDestroy$),
      ).subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.clicked.emit($event);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}

<div class="page-container">
  <div class="page">
    <div class="page__header"
         appDeviceSize>
      <div class="main-header">
        <div class="header-start">
          <ng-content select="[header]"></ng-content>
        </div>
        <div class="page__title">
          @if(title) {
          <span class="title">{{ title }}</span>
          }
        </div>
      </div>
      <div class="sub-header">
        <ng-content select="[sub-header]"></ng-content>
      </div>
    </div>

    <div class="page__content">
      <permission-checker [roles]="roles"
                          (contentInit)="loading.set(false); contentInit.emit()"
                          (rolesLoaded)="rolesLoaded.emit()">
        <app-progress [active]="loading()" />
        <div [style.padding.px]="10">
          <ng-content></ng-content>
        </div>
      </permission-checker>
    </div>
    <div class="page__footer">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
  <div>

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environment';
import { Client, ClientDetails, ClientWebhook, ClientWebhookEvent } from '@app/components/common/model/client';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private http = inject(HttpClient);

  private static readonly BASE_URL = '/client';
  private static readonly CONFIG_URL = `${ClientService.BASE_URL}/config`;
  private static readonly CLIENTS_BASE_URL = '/clients';
  private static readonly CLIENT_WEBHOOK_URL = '/webhooks';

  readonly webhookEvents = [ClientWebhookEvent.order_shipped, ClientWebhookEvent.order_action_needed];

  public getClientConfig(): Observable<Client> {
    return this.http.get<Client>(`${environment.api.integration}${ClientService.CONFIG_URL}`);
  }

  public getClientWebhooks(): Observable<ClientWebhook[]> {
    return this.http.get<ClientWebhook[]>(`${environment.api.integration}${ClientService.CLIENT_WEBHOOK_URL}`).pipe(
      map(webhooks => webhooks.filter(w => Boolean(w.email)))
    );
  }

  public createClientWebhook(email: string, event: ClientWebhookEvent): Observable<ClientWebhook> {
    return this.http.post<ClientWebhook>(`${environment.api.integration}${ClientService.CLIENT_WEBHOOK_URL}`, {
      email, events: [event]
    });
  }

  public deleteClientWebhook(webhookId: string): Observable<void> {
    return this.http.delete<void>(`${environment.api.integration}${ClientService.CLIENT_WEBHOOK_URL}/${webhookId}`);
  }

  public getClients(): Observable<ClientDetails[]> {
    return this.http.get<ClientDetails[]>(`${environment.api.host}${ClientService.CLIENTS_BASE_URL}`);
  }

}

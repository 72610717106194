import { Pipe, PipeTransform } from '@angular/core';
import { OrderBase } from '@app/components/common';
import { DataUtil } from '@app/helper';

@Pipe({
  name: 'shippingService',
  standalone: true,
})
export class ShippingServicePipe implements PipeTransform {

  transform(order: OrderBase): string {
    if (!order.carrier) {
      return order.shippingCode || '-';
    } else if (['economy', 'standard-ground', 'express', 'express-2day', 'express-3day', 'priority-overnight'].includes(order.shippingCode || '-')) {
      return DataUtil.capitalizeFirstLettersForSentence(order.shippingCode, true);
    } else {
      return `${order.carrier} | ${order.shippingCode || '-'}`;
    }
  }

}

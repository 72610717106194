import {
  ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, signal,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { Observable } from 'rxjs';
import { NotificationHandler } from '../../helper/notification.handler';
import { LoadingState } from '@app/common/model';

@Component({
  selector: 'item-select',
  standalone: true,
  templateUrl: './item-select.component.html',
  styleUrls: ['./item-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTooltipModule,
  ],
})
export class ItemSelectComponent<T> implements OnInit, OnChanges {
  private destroyRef = inject(DestroyRef);
  readonly LoadingState = LoadingState;
  readonly loadingState = signal(LoadingState.INIT);

  @Input() label!: string;
  @Input() disabled = false;
  @Input() required = false;
  @Input() clearable = false;
  @Input() showHint = true;

  @Input() compareWith: (i1?: T, i2?: T) => boolean = this.comparator;
  @Input() displayWith!: (item: T) => string;
  @Input() isItemDisabled?: (item: T) => boolean;
  @Input() loadWith!: () => Observable<T[]>;
  @Input() selectedItem?: T | null;

  @Output() selected = new EventEmitter<T>();
  @Output() loaded = new EventEmitter<T[]>();

  items = signal<T[]>([]);

  ngOnInit(): void {
    this.reloadData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loadWith']?.currentValue) {
      this.reloadData();
    }
  }

  public reloadData(): void {
    this.loadItems();
  }

  private loadItems() {
    this.loadingState.set(LoadingState.LOADING);
    this.items.set([]);

    this.loadWith()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: items => {
          this.items.set(items || []);
          this.loadingState.set(LoadingState.LOADED);

          this.loaded.emit(items);

          if (this.selectedItem) {
            const selected = this.items().filter(item => this.compareWith(item, this.selectedItem || undefined));
            if (selected && selected.length > 0) {
              this.selected.emit(selected[0]);
            }
          }
        },
        error: error => {
          this.loadingState.set(LoadingState.ERROR);
          NotificationHandler.handleError('Items fetch has failed', error);
        }
      });
  }

  private comparator(c1?: unknown, c2?: unknown): boolean {
    return !!c1 && !!c2 && c1 === c2;
  }

  itemSelected(change: MatSelectChange) {
    this.selectedItem = change.value;
    this.selected.emit(change.value as T);
  }

  checkItemDisabled(item: T): boolean {
    if (this.isItemDisabled) return this.isItemDisabled(item);
    return false;
  }

}

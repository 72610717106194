<div class="file-upload"
     [ngClass]="{ disabled: disabled }">
  <div class="file-upload-container"
       [ngClass]="{ disabled: disabled }"
       (click)="fileInput.click()"
       fileDragDrop
       (fileDropped)="dropFile($event)">
    <input hidden
           type="file"
           [disabled]="disabled"
           #fileInput
           [accept]="fileTypes"
           [multiple]="multiSelect"
           (change)="selectFile($event)">
    <mat-icon color="accent"
              class="upload-icon">cloud_upload</mat-icon>
    <div>
      @if(disabled) {
      Upload disabled
      }
      @else {
      Drag and drop images here or click to browse from your device
      }
    </div>
    @if(acceptTypes.length) {
    <div class="file-types-note text-disabled-color">Allowed file types: {{acceptTypes}}</div>
    }
  </div>

  @if(allowUrl) {
  <span [style.text-align]="'center'">or</span>
  <div class="file-url-container">
    <mat-form-field class="file-url-field">
      <mat-label>Paste URL {{ (urlProcessing$ | async) ? ' (Processing...)' : '' }}</mat-label>
      <input matInput
             [disabled]="disabled"
             placeholder="URL"
             appDebounceInput
             [(ngModel)]="urlInput"
             (debouncedInput)="handleUrlChange($event)">
    </mat-form-field>
  </div>
  }

  @if(showSelected) {
  <div class="files">
    @for(file of files; track file; let i = $index) {
    <div class="file-upload-selected-file">
      <p class="selected-file"> {{ file?.name }} </p>

      <button mat-icon-button
              (click)="deleteAttachment(i)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    }

    @if(!files.length) {
    <div class="text-disabled-color">
      <div class="center-middle">No files selected</div>
    </div>
    }
  </div>
  }
</div>

import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { LoadingState } from '@app/common/model';
import { ProgressContainerComponent } from '../progress-container';

@Component({
  selector: 'retryable-image',
  standalone: true,
  templateUrl: './retryable-image.component.html',
  styleUrls: ['./retryable-image.component.scss'],
  imports: [
    ProgressContainerComponent,
    TitleCasePipe,
  ],
})
export class RetryableImageComponent {
  static readonly DEFAULT_RETRY_COUNT = 5;
  static readonly MAX_RETRY_INTERVAL = 20 * 1000;

  protected readonly LoadingState = LoadingState;
  protected readonly loadingState = signal<LoadingState>(LoadingState.INIT);
  protected readonly url = signal<string | undefined>(undefined);

  @Input() maxRetry = RetryableImageComponent.DEFAULT_RETRY_COUNT;

  private retryCount = 0;
  private timeout?: ReturnType<typeof setTimeout>;
  private interval = 1000;

  private source?: string;

  @Input() alt = 'image';
  @Input() set src(data: string | undefined) {
    this.source = data;
    this.url.set(data);
    this.clearState(LoadingState.LOADING);
  }

  @Output() imageLoadAttempted = new EventEmitter<void>();

  handleImageLoad(): void {
    this.imageLoadAttempted.emit();
    this.clearState(LoadingState.LOADED);
  }

  handleImageLoadFailed(): void {
    if (this.maxRetry > this.retryCount) {
      this.retry();
    } else {
      this.clearState(LoadingState.ERROR);
    }
  }

  private retry() {
    this.interval = Math.min(this.interval * 2, RetryableImageComponent.MAX_RETRY_INTERVAL);
    this.url.set(undefined);

    this.timeout = setTimeout(
      () => {
        this.url.set(this.source);
        this.imageLoadAttempted.emit();
        this.retryCount += 1;
      },
      this.interval,
    );
  }

  private clearState(state: LoadingState): void {
    if (this.timeout) clearTimeout(this.timeout);
    this.retryCount = 0;
    this.loadingState.set(state);
  }
}

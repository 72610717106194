enum MimeType {
  text = 'text/plain',
  csv = 'text/csv',
}

export class DownloadUtil {
  public static async downloadRemoteFile(url: string, filename: string) {
    const response = await fetch(url);
    const objectURL = URL.createObjectURL(await response.blob());

    const link = window.document.createElement('a');
    link.href = objectURL;
    link.download = filename;
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  public static downloadTextAsFile(text: string, filename: string, extension: 'txt' | 'csv' | 'jpg' = 'txt') {
    let type = MimeType.text;
    if (extension === 'csv') {
      type = MimeType.csv;
    }

    const blob = new Blob([text], { type });
    const objectURL = window.URL.createObjectURL(blob);

    const link = window.document.createElement('a');
    link.href = objectURL;
    link.download = `${filename}.${extension || 'txt'}`;
    link.click();

    window.URL.revokeObjectURL(objectURL);
  }

  public static downloadDataUrlAsFile(dataURL: string, filename: string, extension: 'txt' | 'csv' | 'jpg' = 'txt') {

    const link = window.document.createElement('a');
    link.href = dataURL;
    link.download = `${filename}.${extension || 'txt'}`;
    link.click();

    window.URL.revokeObjectURL(dataURL);
  }
}

import { Component, input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

type PreloaderColor = 'primary' | 'accent' | 'warn';

@Component({
  standalone: true,
  selector: 'app-progress',
  templateUrl: './progress-container.component.html',
  styleUrls: ['./progress-container.component.scss'],
  imports: [
    MatProgressSpinnerModule,
  ],
})
export class ProgressContainerComponent {
  readonly size = input<'small' | 'medium' | 'large'>('medium');
  readonly active = input<boolean>(true);
  readonly color = input<PreloaderColor>('primary');

  get diameter() {
    const size = this.size();
    switch (size) {
      case 'small':
        return 20;
      case 'medium':
        return 40;

      case 'large':
        return 60;

      default:
        return 40;
    }
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { DeviceSizeDirective } from '@app/common/directives';
import { Role } from '@app/common/model/auth/role';
import { PermissionCheckerComponent, ProgressContainerComponent } from '@app/common/components';

@Component({
  standalone: true,
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DeviceSizeDirective,
    PermissionCheckerComponent,
    ProgressContainerComponent
],
})
export class PageComponent {
  readonly Role = Role;
  @Input() title!: string;

  @Input() roles: Role[] = [Role.admin];

  @Output() contentInit = new EventEmitter<void>();
  @Output() rolesLoaded = new EventEmitter<Role[]>();

  readonly loading = signal(true);
}

@if (subscription) {
<mat-card [style.padding.px]="10"
          [style.height.px]="250"
          class="subscription"
          appearance="outlined">
  <mat-card-header>
    <mat-card-title>
      @for (event of subscription.events; track event) {
      <code>{{ event | replaceAll:'_':' ' | uppercase }}</code>
      }
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    @if (subscription.id) {
    <p>{{subscription.email }}</p>
    }
    @else {
    <form-input [control]="emailField"
                [required]="true"
                label="Email"
                placeholder="User email..."
                type="email"
                [minLength]="5"
                [maxLength]="128"></form-input>
    }

  </mat-card-content>
  <mat-card-footer [style.padding]="'16px 0 0 0'"
                   [style.text-align]="'center'">
    @if (subscription.id) {
    <app-button label="Unsubscribe"
                class="cta-btn center"
                [destructive]="!!subscription.id"
                (clicked)="deleteSubscription.emit(subscription.id)"></app-button>
    }
    @else {
    <app-button label="Subscribe"
                class="cta-btn center"
                [disabled]="emailField.invalid"
                (clicked)="createSubscription.emit({ email: emailField.value || '', event: subscription.events[0] })"></app-button>
    }
  </mat-card-footer>
</mat-card>
}

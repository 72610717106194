import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-link',
  standalone: true,
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  imports: [
    MatIconModule,
    NgIf,
    RouterLink,
  ],
})
export class LinkComponent implements OnInit {

  @Input() label = 'url';
  @Input() routerLink: string | undefined;
  @Input() href: string | undefined;
  @Input() external = false;

  @Input() icon?: string;

  externalRouterLink?: string;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    if (this.external && this.routerLink) {
      // Converts the route into a string that can be used with the window.open() function
      this.externalRouterLink = window.location.origin + this.router.serializeUrl(
        this.router.createUrlTree([this.routerLink])
      );
    }
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten',
  standalone: true,
})
export class ShortenPipe implements PipeTransform {

  transform(value: string | undefined, maxLen: number, placeholder: string = ''): unknown {
    return (value?.slice(0, maxLen) + (value && value.length > maxLen ? '...' : '')) || placeholder || '';
  }

}

import { UpperCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { CommonComponentsModule } from '@app/common/common.module';
import { CustomValidators } from '@app/common/helper/custom.validators';
import { ReplaceAllPipe } from '@app/common/pipes';
import { ClientWebhook, ClientWebhookEvent } from '@app/components/common';

@Component({
  standalone: true,
  selector: 'app-event-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  imports: [
    CommonComponentsModule,
    MatCardModule,
    UpperCasePipe,
    ReplaceAllPipe,
  ],
})
export class EventSubscriptionComponent {
  @Input() set defaultEmail(email: string) {
    this.emailField.setValue(email);
  }
  @Input() subscription?: (Pick<ClientWebhook, 'events'> & Partial<Pick<ClientWebhook, 'id' | 'email'>>);
  @Output() createSubscription = new EventEmitter<{ email: string; event: ClientWebhookEvent; }>();
  @Output() deleteSubscription = new EventEmitter<string>();

  protected emailField = new FormControl('', [
    CustomValidators.email, Validators.required, Validators.minLength(5), Validators.maxLength(128)
  ]);
}

@let imageSource = url();
@let state = loadingState();

<div class="image image-container">
  <app-progress [active]="state === LoadingState.LOADING"
                [style.position]="'absolute'" />

  @if (state === LoadingState.ERROR) { {{ alt | titlecase }} loading failed }

  @if (imageSource) {
  <img [src]="imageSource"
       [alt]="alt"
       [hidden]="state !== LoadingState.LOADED"
       class="image"
       (load)="handleImageLoad()"
       (error)="handleImageLoadFailed()" />
  }
</div>
